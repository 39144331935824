import React from 'react';
import { InputNumber } from 'primereact/inputnumber';

const CalculatorStep7 = ({ step, setStep, gridFee, setGridFee, handleResult }) => {
	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<p className="calculator-question">
				Értem, jelenleg mennyibe kerül a rendszerhasználati díj nálad? (Ft/kWh)
			</p>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<InputNumber
					value={gridFee}
					onValueChange={(e) => setGridFee(e.value)}
					mode="decimal"
					min={0}
					showButtons
					placeholder="Rendszerhasználati díj Ft/kWh"
				/>
			</div>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					<p className="grey-button-text">Vissza</p>
				</button>
				<button className="next-button" onClick={handleResult}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep7;
