import React from 'react';
import { RadioButton } from 'primereact/radiobutton';

const CalculatorStep6 = ({ step, setStep, includeGridFee, setIncludeGridFee, handleResult }) => {
	const handleButtonClick = () => {
		if (includeGridFee) {
			handleResult();
		} else {
			setStep((prevStep) => prevStep + 1);
		}
	};

	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<div style={{ alignItems: 'center', justifyContent: 'center' }}>
				<p className="calculator-question">És ebben benne van a rendszerhasználati díj is?</p>
				<div className="checkbox-outer">
					<div className="checkbox-wrapper">
						<label className="calculator-answer">
							<RadioButton
								value={true}
								onChange={(e) => setIncludeGridFee(e.value)}
								checked={includeGridFee === true}
							/>
							Igen
						</label>
					</div>
					<div className="checkbox-wrapper">
						<label className="calculator-answer">
							<RadioButton
								value={false}
								onChange={(e) => setIncludeGridFee(e.value)}
								checked={includeGridFee === false}
							/>
							Nem
						</label>
					</div>
				</div>
				<div className="buttons-wrapper">
					<button className="grey-button" onClick={handleBack}>
						<p className="grey-button-text">Vissza</p>
					</button>
					<button className="next-button" onClick={handleButtonClick}>
						<p className="next-button-text">Tovább</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default CalculatorStep6;
