import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CalculatorStep4 = ({ step, setStep, installationArea, setInstallationArea }) => {
	const notify = () =>
		toast.warn('Legalább egy válasz kötelező!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const areas = [
		'Budapest / Pest megye',
		'Észak-Magyarország',
		'Észak-Alföld',
		'Dél-Alföld',
		'Közép-Dunántúl',
		'Dél-Dunántúl',
		'Nyugat-Dunántúl',
	];

	const handleNext = () => {
		if (installationArea) {
			setStep(step + 1);
		} else {
			notify();
		}
	};

	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<p className="calculator-question">Okés, értem. Helyileg az ország melyik részén van a telephelyetek?</p>
			<div className="checkbox-outer">
				{areas.map((area) => (
					<div key={area} className="checkbox-wrapper">
						<label htmlFor={area} className="calculator-answer">
							<RadioButton
								inputId={area}
								name="area"
								value={area}
								onChange={(e) => setInstallationArea(e.value)}
								checked={installationArea === area}
							/>

							{area}
						</label>
					</div>
				))}
			</div>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					<p className="grey-button-text">Vissza</p>
				</button>
				<button className="next-button" onClick={handleNext}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep4;
