import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CalculatorStep3 = ({ step, setStep, installationRegion, setInstallationRegion }) => {
	const notify = () =>
		toast.warn('Legalább egy válasz kötelező!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const handleNext = () => {
		if (installationRegion) {
			setStep(step + 1);
		} else {
			notify();
		}
	};
	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<p className="calculator-question">Szuper, köszönöm! Milyen tájolású a hely, ahova telepítened?</p>
			<div className="checkbox-outer">
				<div className="checkbox-wrapper">
					<label htmlFor="north" className="calculator-answer">
						<RadioButton
							inputId="north"
							name="region"
							value="Észak"
							onChange={(e) => setInstallationRegion(e.value)}
							checked={installationRegion === 'Észak'}
						/>
						Leginkább északi
					</label>
				</div>
				<div className="checkbox-wrapper">
					<label htmlFor="south" className="calculator-answer">
						<RadioButton
							inputId="south"
							name="region"
							value="Dél"
							onChange={(e) => setInstallationRegion(e.value)}
							checked={installationRegion === 'Dél'}
						/>
						Leginkább déli
					</label>
				</div>
				<div className="checkbox-wrapper">
					<label htmlFor="east" className="calculator-answer">
						<RadioButton
							inputId="east"
							name="region"
							value="Kelet"
							onChange={(e) => setInstallationRegion(e.value)}
							checked={installationRegion === 'Kelet'}
						/>
						Leginkább keleti
					</label>
				</div>
				<div className="checkbox-wrapper">
					<label htmlFor="west" className="calculator-answer">
						<RadioButton
							inputId="west"
							name="region"
							value="Nyugat"
							onChange={(e) => setInstallationRegion(e.value)}
							checked={installationRegion === 'Nyugat'}
						/>
						Leginkább nyugati
					</label>
				</div>
			</div>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					<p className="grey-button-text">Vissza</p>
				</button>
				<button className="next-button" onClick={handleNext}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep3;
