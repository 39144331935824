import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CalculatorStep4 = ({ step, setStep, electricityPrice, setElectricityPrice }) => {
	const notify = () =>
		toast.warn('Adj meg árat!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const handleNext = () => {
		if (
			electricityPrice !== 0 &&
			electricityPrice !== '' &&
			electricityPrice !== null &&
			electricityPrice !== undefined
		) {
			setStep(step + 1);
		} else {
			notify();
		}
	};

	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};

	return (
		<div className="calculator_wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<p className="calculator-question">Köszönöm szépen. És nettó mennyiért veszed jelenleg az áramot?</p>
			<p className="calculator-sub-question">(Ft/Kwh-ban kifejezve)</p>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div style={{display:'flex', maxWidth:800}}>
					<InputText
						keyfilter="int"
						value={electricityPrice}
						onChange={(e) => {
							setElectricityPrice(e.target.value);
						}}
						mode="decimal"
						placeholder="Áram ár Ft/kWh"
						showButtons
					/>
					<div style={{ display: 'flex', flexDirection: 'column', marginLeft: '-3px' }}>
						<Button
							icon="pi pi-chevron-up"
							style={{ height: 25, borderRadius: '5px 5px 0px 0px' }}
							onClick={() => setElectricityPrice(electricityPrice + 1)}
						/>
						<Button
							icon="pi pi-chevron-down"
							style={{ height: 25, borderRadius: '0px 0px 5px 5px' }}
							onClick={() => setElectricityPrice(electricityPrice - 1)}
						/>
					</div>
				</div>
			</div>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					<p className="grey-button-text">Vissza</p>
				</button>
				<button className="next-button" onClick={handleNext}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep4;
