import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CalculatorStep2 = ({ step, setStep, systemSize, setSystemSize, isThinking }) => {
	const notify = () =>
		toast.warn('Legalább egy válasz kötelező!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const handleNext = () => {
		if (systemSize) {
			setStep(step + 1);
		} else {
			notify();
		}
	};

	const handleBack = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			{isThinking && (
				<p className="calculator-question">
					Ezt jó hallani! Mit gondolsz, ideálisan mekkora rendszerre lenne szükséged?
				</p>
			)}
			{!isThinking && (
				<p className="calculator-question">
					Köszönöm, hogz megosztottad ezt. Ha mégis telepítenél egy napelemparkot, mit gondolsz, ideálisan
					mekkora rendszerre lenne szükséged?
				</p>
			)}
			<div className="checkbox-outer">
				<div className="checkbox-wrapper">
					<label className="calculator-answer">
						<RadioButton
							value="100"
							onChange={(e) => setSystemSize(e.value)}
							checked={systemSize === '100'}
						/>
						100 kWh
					</label>
				</div>
				<div className="checkbox-wrapper">
					<label className="calculator-answer">
						<RadioButton
							value="200"
							onChange={(e) => setSystemSize(e.value)}
							checked={systemSize === '200'}
						/>
						200 kWh
					</label>
				</div>
				<div className={systemSize === '300' ? 'checkbox-wrapper-selected' : 'checkbox-wrapper'}>
					<label className="calculator-answer">
						<RadioButton
							value="300"
							onChange={(e) => setSystemSize(e.value)}
							checked={systemSize === '300'}
						/>
						300 kWh
					</label>
				</div>
				<div className="checkbox-wrapper">
					<label className="calculator-answer">
						<RadioButton
							value="500"
							onChange={(e) => setSystemSize(e.value)}
							checked={systemSize === '500'}
						/>
						Több, mint 300 kWh
					</label>
				</div>
			</div>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					Vissza
				</button>
				<button className="next-button" onClick={handleNext}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep2;
