import React, { useState, useEffect } from 'react';
import { useResults } from '../../ResultsContext';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Results = () => {
	const { results } = useResults();
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState('');
	const [checked, setChecked] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [email, setEmail] = useState('');
	const [companyName, setCompanyName] = useState('');

	function formatNumberToHungarian(number) {
		if (number === undefined) {
			return '';
		}
		return number.toLocaleString('hu-HU');
	}

	useEffect(() => {
		if (Object.values(results).every((value) => value == null)) {
			navigate('/');
		}
	}, [results, navigate]);

	const notifyGDPR = () =>
		toast.warn('Az adatvédelmi tájékoztató elfogadása kötelező!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const notifyEmail = () =>
		toast.warn('Ezzel az email címmel már feliratkoztak!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const notifyMissingEmail = () =>
		toast.warn('Add meg az email címed!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const notifyError = () =>
		toast.warn('Hiba történt a beküldéskor kérlek próbáld meg újra!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});

	const formId = '6459266';
	const apiSecret = 'FU0QetC8A7AfcevHwsoHOBslMhLZ-VdjDrJivdE6GGc';
	const apiPublic = '7h8w0bheDkcMlq8xR-FjEQ';

	const apiCall = async () => {
		try {
			const subscriptionsResponse = await fetch(
				`https://api.convertkit.com/v3/forms/6459266/subscriptions?api_secret=${apiSecret}`
			);
			const subscriptionsData = await subscriptionsResponse.json();

			const isEmailExists = subscriptionsData.subscriptions.some(
				(subscription) => subscription.subscriber.email_address === email
			);

			if (isEmailExists) {
				notifyEmail();
				return;
			}

			// Ha az email cím még nem szerepel az adatbázisban, akkor folytatjuk a feliratkozást
			const response = await fetch(`https://api.convertkit.com/v3/forms/${formId}/subscribe`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					api_key: apiPublic,
					email: email,
					first_name: firstName,
					eves_megtakaritas: results.annualSavings,
					extra_aram: results.monthlyYield,
					havi_megtakaritas: results.monthlySavings,
					igenyelt_terulet: results.areaRequired,
					megye: results.installationArea,
					netto_aram_egysegar: results.electricityPrice,
					rendszerhasznalati_dij: results.gridFee,
					rendszer_meret: results.systemSize,
					tajolas: results.installationRegion,
					fields: {
						cegnev: companyName,
						monthly_yield: results.monthlyYield,
						area_required: results.areaRequired,
						monthly_savings: results.monthlySavings,
						eves_megtakaritas: results.annualSavings,
						extra_aram: results.monthlyYield,
						havi_megtakaritas: results.monthlySavings,
						igenyelt_terulet: results.areaRequired,
						megye: results.installationArea,
						netto_aram_egysegar: results.electricityPrice,
						rendszerhasznalati_dij: results.gridFee,
						rendszer_meret: results.systemSize,
						tajolas: results.installationRegion,
					},
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			setIsSubmitted(true);
		} catch (error) {
			console.error('A fetch hívás során hiba történt:', error);
			setIsSubmitted(false);
			notifyError();
		}
	};

	const submitForm = () => {
		if (!checked) {
			notifyGDPR();
			return;
		} else if (!email) {
			notifyMissingEmail();
			return;
		}
		apiCall().then(() => {
			window.location.hash = 'endscreen';
		});
	};

	return (
		<main className="result-wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			{!isSubmitted && (
				<>
					<h1 className="result-title">Köszönöm, hogy kitöltötted ezt a rövid kalkulátort!</h1>
					<div className="result-input-wapper">
						<p className="result-input-text" style={{ marginTop: 20 }}>
							Kérlek add meg az email címed, és már küldöm is, hogy mennyi pénzt takaríthat meg cégednek
							egy saját, számodra megfelelő napelempark.
						</p>
						<div className="result-input">
							<p className="result-input-text">Mi a keresztneved?</p>
							<InputText value={firstName} onChange={(e) => setFirstName(e.target.value)} />
						</div>
						<div className="result-input">
							<p className="result-input-text">Mi a leginkább használd email címed?</p>
							<InputText type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						<div className="result-input">
							<p className="result-input-text">Mi a céged neve?</p>
							<InputText
								value={companyName}
								type="email"
								onChange={(e) => setCompanyName(e.target.value)}
							/>
						</div>
						<div className="result-acceptance-wrapper">
							<Checkbox onChange={(e) => setChecked(e.checked)} checked={checked}></Checkbox>
							<label className="result-acceptance">
								Elfogadom az{' '}
								<a
									href="https://autonom-napenergia.hu/adatkezetesi-nyilatkozat/"
									rel="noreferrer"
									target="_blank"
								>
									adatvédelmi irányelveket
								</a>
							</label>
						</div>
						<button className="next-button" onClick={submitForm}>
							<p className="next-button-text">Kérem az eredményeimet!</p>
							<i className="pi pi-chevron-right"></i>
						</button>
					</div>
				</>
			)}
			{isSubmitted && (
				<>
					<h1 id="endscreen" className="result-title">
						A válaszaid alapján a következő értékekkel számolhatsz:
					</h1>
					<div className="result-input-wapper">
						<p className="result-text">A válaszaid alapján a következő értékekkel számolhatsz:</p>
						<p className="result-text">
							Kb ennyi áramot tudnál egy átlag hónapban megtermelni ingyen:{' '}
							<span className="result-input-text">
								<br />
								{formatNumberToHungarian(results.monthlyYield)} KW{' '}
							</span>
						</p>
						<p className="result-text">
							Egy ekkora napelempark telepítése ekkora területet igényel: <br />
							<span className="result-input-text">
								{formatNumberToHungarian(results.areaRequired)} nm
							</span>
						</p>
						<p className="result-text">
							Ennyi pénzt tudnál megtakarítani havonta: <br />
							<span className="result-input-text">
								{formatNumberToHungarian(results.monthlySavings)} Ft
							</span>
						</p>
						<p className="result-text">
							És ennyi pénz maradna a zsebedben évente: <br />
							<span className="result-input-text">
								{formatNumberToHungarian(results.annualSavings)} Ft
							</span>
						</p>
						<a href="#next-step" className="next-button" style={{ marginTop: 20 }}>
							<p className="next-button-text">Hogyan tovább?</p>
							<i className="pi pi-chevron-right"></i>
						</a>
						<div id="next-step">
							<p className="result-text">
								Ha a kalkulátor kitöltése után úgy gondolod, hogy érdekel egy naperőmű megépítésének
								lehetősége, energiatárolóval, és esetleg szeretnél pályázni valamilyen most elérhető
								konstrukcióban – viszont még nem teljesen tiszta minden részlet…
							</p>
							<p className="result-text">
								Akkor szeretnék felajánlani számodra egy{' '}
								<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
									részletesebb telefonos konzultációt
								</span>
								, aminek keretén belül átbeszéljük a helyzetet, elmondod hogy kb mit szeretnél, én pedig
								visszajelzem, hogy legjobb tudásom szerint mint ajánlanék számodra.
							</p>
							<p className="result-input-text" style={{ marginTop: 20 }}>
								Szeretném, hogy előre tudd, hogy ez NEM egy értékesítési hívás!
							</p>
							<p className="result-text">
								Nem fogok megpróbálni eladni semmit, és nem az a célom, hogy mindenképp eladjak valami
								olyasmit, amire nincs is szükséged.
							</p>
							<p className="result-text">
								Rengetegen csinálják ezt alapértelmezett módon, de nekem nem ez a stílusom.
							</p>
							<p className="result-text">
								Ehelyett ennek az rövid telefonbeszélgetésnek az a célja, hogy jobban megértsem, hogy
								mit is szeretnél igazán.
							</p>
							<p className="result-text">
								De ha netán a beszélgetés után úgy érzed, hogy szeretnéd folytatni a párbeszédet, akkor
								természetesen erre is van lehetőség.
							</p>
							<p className="result-text">
								De ha nem, vagy másmire van szükséged (vagy nincs szükséged senki másra), akkor azt is
								megmondom őszintén.
							</p>
							<p className="result-input-text" style={{ marginTop: 20 }}>
								Hogy miért csinálom ezt ahelyett, hogy keményen megpróbálnék értékesíteni valamit?
							</p>
							<p className="result-text">
								A válasz nagyon egyszerű: mert tudom, hogy minden X-edik ember annyira hasznosnak
								találja a beszélgetésünk, hogy ő kér fel arra, hogy dolgozzunk együtt.
							</p>
							<p className="result-text">
								Nem azért mert annyira{' '}
								<span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
									“cselesen túljártam az eszén”
								</span>
								, hanem mert saját maga megtapasztalta azt, hogy én máshogy szeretem csinálni a
								dolgokat, és ez megtetszett neki.
							</p>
							<p className="result-text">Ilyen egyszerű, tényleg 🙂</p>
							<p className="result-text">
								Viszont ez a lehetőség CSAK azoknak érhető el, akik kitöltik a kalkulátort (mivel így
								igazán hatékonyak tudunk lenni a beszélgetés során).
							</p>
							<p className="result-text">
								Szóval ha mindez felkeltette az érdeklődésed, akkor keress bizalommal az alábbi
								telefonszámon (és mindenképp említsd meg, hogy kalkuláltál!)
							</p>
							<br />
							<a href="tel:+36705508286" className="result-input-text">
								+36705508286
							</a>
							<p className="result-text">
								Vagy csak küldj egy emailt a{' '}
								<a href="mailto:tamas@autonomenergy.hu">tamas@autonomenergy.hu</a>-ra, és amint tudok
								válaszolok.
							</p>
							<p className="result-text">Köszönöm, hogy elolvastad!</p>
							<p className="result-text">Üdv,</p>
							<p className="result-text">Kotulyák Tamás</p>{' '}
							<p className="result-text">Autonom Energy Kft.</p>
						</div>
					</div>
				</>
			)}
		</main>
	);
};

export default Results;
