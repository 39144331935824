import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './pages/landing/Landing';
import Calculator from './pages/calculator/Calculator';
import Results from './pages/results/Results';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-teal/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import { ResultsProvider } from './ResultsContext';

function App() {
	return (
		<PrimeReactProvider>
			<Router>
				<ResultsProvider>
					<Routes>
						<Route path="/" element={<Landing />} />
						<Route path="/kalkulator" element={<Calculator />} />
						<Route path="/eredmenyek" element={<Results />} />
					</Routes>
				</ResultsProvider>
			</Router>
		</PrimeReactProvider>
	);
}

export default App;
