import React, { createContext, useContext, useState } from 'react';

const ResultsContext = createContext();

export function useResults() {
    return useContext(ResultsContext);
}

export const ResultsProvider = ({ children }) => {
    const [results, setResults] = useState({});

    const storeResults = (resultsData) => {
        setResults(resultsData);
    };

    return (
        <ResultsContext.Provider value={{ results, storeResults }}>
            {children}
        </ResultsContext.Provider>
    );
};
