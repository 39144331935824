import React from 'react';
import 'primeicons/primeicons.css';
//import '../../theme.css';

const Landing = () => {
	const navigateToCalculator = () => {
		window.location.href = '/kalkulator';
	};

	return (
		<>
			<main className="landing_main">
				<div className="landing_mobile_image"></div>
				<div className="landing_text_wrapper">
					<h1 className="hero-text">
						Megér számodra 1 percet az, hogy megtudd, hogyan tudsz megspórolni akár 1.061.567 Ft-ot havonta
						a vállalkozásodban?
					</h1>
					<p className="hero-description">
						Töltsd ki az új Napelemtermelő Kalkulátorunkat és fedezd fel, hogy mennyi pénzt takaríthat meg
						cégednek egy saját, számodra megfelelő méretű napelempark!
					</p>
					<button className="next-button" onClick={navigateToCalculator}>
						<p className="next-button-text">Igen - kíváncsi vagyok!</p>
						<i className="pi pi-chevron-right"></i>
					</button>
				</div>
				<div className="landing_image_wrapper"></div>
			</main>
			<div className='gdpr'>
				<a href='https://autonom-napenergia.hu/adatkezetesi-nyilatkozat/' target='_blank' style={{ textAlign: 'center' }}>Adatvédelmi tájékoztató</a>
			</div>
		</>
	);
};

export default Landing;
