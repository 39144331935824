import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResults } from '../../ResultsContext';
import CalculatorStep1 from '../../components/CalculatorStep1';
import CalculatorStep1b from '../../components/CalculatorStep1b';
import CalculatorStep2 from '../../components/CalculatorStep2';
import CalculatorStep3 from '../../components/CalculatorStep3';
import CalculatorStep4 from '../../components/CalculatorStep4';
import CalculatorStep5 from '../../components/CalculatorStep5';
import CalculatorStep6 from '../../components/CalculatorStep6';
import CalculatorStep7 from '../../components/CalculatorStep7';
import { ProgressBar } from 'primereact/progressbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Calculator = () => {
	const [step, setStep] = useState(1);
	const [isThinking, setIsThinking] = useState(false);
	const [systemSize, setSystemSize] = useState(null); // kWp méret választása
	const [installationRegion, setInstallationRegion] = useState(''); // Telepítési terület: Észak, Dél, stb.
	const [installationArea, setInstallationArea] = useState(''); // Megye vagy terület: Pest megye, stb.
	const [electricityPrice, setElectricityPrice] = useState(0); // Áram ára Ft/kWh-ban
	const [includeGridFee, setIncludeGridFee] = useState(true); // Rendszerhasználati díj beletartozik-e
	const [gridFee, setGridFee] = useState(0); // Rendszerhasználati díj Ft/kWh-ban, ha nem tartozik bele
	const [installationBarrier, setInstallationBarrier] = useState(''); // Telepítési akadályok

	function calculateSavings() {
		if (systemSize && installationRegion && electricityPrice && (includeGridFee || gridFee)) {
			const averageYieldPerKwp = { 'Észak': 950, 'Dél': 1150, 'Kelet': 1000, 'Nyugat': 1050 }; // Példa értékek
			const systemYield = Math.round(systemSize * averageYieldPerKwp[installationRegion]);
			const totalCostPerKwh = Math.round(
				parseFloat(electricityPrice) + (includeGridFee ? 0 : parseFloat(gridFee))
			);
			const annualSavings = Math.round(systemYield * totalCostPerKwh);
			const monthlySavings = Math.round(annualSavings / 12);
			const areaRequired = Math.round(systemSize * 10); // Minden kWp 10 m² területet foglal
			const monthlyYield = Math.round(systemYield / 12); // Havi termelés

			// Most már visszaadja az eredményeket egy objektumként
			return {
				installationRegion,
				systemSize,
				electricityPrice,
				includeGridFee,
				gridFee,
				systemYield,
				annualSavings,
				monthlySavings,
				areaRequired,
				monthlyYield,
			};
		} else {
			// Ha nem áll rendelkezésre minden adat, akkor visszaadhat egy üres objektumot vagy hibaüzenetet
			return {
				error: 'Hiányzó adatok. Kérjük, ellenőrizze, hogy minden szükséges mező kitöltése megtörtént.',
			};
		}
	}

	const notify = () =>
		toast.warn('Adj meg árat!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});

	const { storeResults } = useResults();
	const navigate = useNavigate();

	const handleResult = () => {
		if (!includeGridFee && (gridFee === 0 || gridFee === '' || gridFee === null || gridFee === undefined)) {
			notify();
		} else {
			const data = calculateSavings();
			if (!data.error) {
				data.installationBarrier = installationBarrier; // Bővítsd az adatokat a felhasználó válaszával
				storeResults(data);
				navigate('/eredmenyek');
			} else {
				console.error(data.error);
			}
		}
	};

	return (
		<>
			<main className="calculator_main">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				{step === 1 && (
					<CalculatorStep1
						isThinking={isThinking}
						setIsThinking={setIsThinking}
						step={step}
						setStep={setStep}
						calculateSavings={calculateSavings}
					/>
				)}
				{step === 1.5 && (
					<CalculatorStep1b
						installationBarrier={installationBarrier}
						setInstallationBarrier={setInstallationBarrier}
						setStep={setStep}
						step={step}
					/>
				)}
				{step === 2 && (
					<CalculatorStep2
						isThinking={isThinking}
						step={step}
						setStep={setStep}
						systemSize={systemSize}
						setSystemSize={setSystemSize}
					/>
				)}
				{step === 3 && (
					<CalculatorStep3
						step={step}
						setStep={setStep}
						installationRegion={installationRegion}
						setInstallationRegion={setInstallationRegion}
					/>
				)}
				{step === 4 && (
					<CalculatorStep4
						step={step}
						setStep={setStep}
						installationArea={installationArea}
						setInstallationArea={setInstallationArea}
					/>
				)}
				{step === 5 && (
					<CalculatorStep5
						step={step}
						setStep={setStep}
						electricityPrice={electricityPrice}
						setElectricityPrice={setElectricityPrice}
						includeGridFee={includeGridFee}
						setIncludeGridFee={setIncludeGridFee}
						gridFee={gridFee}
						setGridFee={setGridFee}
					/>
				)}
				{step === 6 && (
					<CalculatorStep6
						step={step}
						setStep={setStep}
						includeGridFee={includeGridFee}
						setIncludeGridFee={setIncludeGridFee}
						handleResult={handleResult}
					/>
				)}
				{step === 7 && (
					<CalculatorStep7
						step={step}
						setStep={setStep}
						calculateSavings={calculateSavings}
						gridFee={gridFee}
						setGridFee={setGridFee}
						handleResult={handleResult}
					/>
				)}
			</main>
			<footer className="progressbar-wrapper">
				{step !== 1 && (
					<div className="progress">
						<p className="calculator-description">Itt tartasz most:</p>
						<ProgressBar
							value={step * 14}
							style={{ width: '100%', height: 20, marginTop: 20 }}
							displayValueTemplate={(value) => `${value}%`}
						/>
					</div>
				)}
			</footer>
			<div className="gdpr">
				<a
					href="https://autonom-napenergia.hu/adatkezetesi-nyilatkozat/"
					target="_blank"
					style={{ textAlign: 'center' }}
				>
					Adatvédelmi tájékoztató
				</a>
			</div>
		</>
	);
};

export default Calculator;
