import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CalculatorStep1b = ({ installationBarrier, setInstallationBarrier, step, setStep }) => {
	const notify = () =>
		toast.warn('Kérlek írd le, hogy miért!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const handleNext = () => {
		if (installationBarrier) {
			setStep(2);
		} else {
			notify();
		}
	};

	const handleBack = () => {
		if (step > 1) {
			setStep(1);
		}
	};
	return (
		<div className="calculator_wrapper">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<p className="calculator-question">
				Értem. És mi az, ami meggátolt eddig abban, hogy napelemparkot telepíts?
			</p>
			<InputTextarea
				rows={10}
				cols={30}
				value={installationBarrier}
				onChange={(e) => setInstallationBarrier(e.target.value)}
				autoResize
			/>
			<div className="buttons-wrapper">
				<button className="grey-button" onClick={handleBack}>
					Vissza
				</button>
				<button className="next-button" onClick={handleNext}>
					<p className="next-button-text">Tovább</p>
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep1b;
