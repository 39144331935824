import React from 'react';

const CalculatorStep1 = ({ setIsThinking, step, setStep }) => {
	const handleClick = (value) => {
		setIsThinking(value);
		if (value) {
			setStep(step + 1); // Ha igen, lépj a következő lépésre
		} else {
			setStep(1.5); // Ha nem, lépj az új lépésre (1.5)
		}
	};
	return (
		<div className="calculator_wrapper">
			<h1 className="calculator-title">Napelempark Kalkulátor</h1>
			<p className="calculator-description">Gondolkoztál már azon, hogy telepíts egy napelemparkot?</p>
			<div className="calculator-button-wrapper">
				<button className="choose-button" onClick={() => handleClick(true)}>
					Igen
				</button>
				<button className="choose-button" onClick={() => handleClick(false)}>
					Nem
				</button>
			</div>
		</div>
	);
};

export default CalculatorStep1;
